<template>
  <div id="new" v-if="unHideTweet">
    <div
      class="news__header position-relative w-100"
      :tabindex="tabindex"
      @blur="showOption = false"
      style="outline: none; box-shadow: none"
    >
      <div
        class="image-info"
        @click="redirectProfile()"
        style="cursor: pointer"
      >
        <div class="image">
          <img :src="propsNew.user.imageUrl" class="avatar" />
        </div>
      </div>
      <div class="d-flex flex-column w-100">
        <div class="name d-flex justify-content-between">
          <div class="name__title f-w6">
            {{ propsNew.user.nickname }}
          </div>
        </div>
        <div
          class="score d-flex justify-content-between align-items-center f-w3"
          style="outline: none; box-shadow: none"
        >
          <div class="d-flex">
            <span class="score-best">BEST: {{ propsScore.best_score }}</span>
            <span class="score-avg">AVG: {{ propsScore.avg_score }}</span>
          </div>
          <div class="drop-heart d-flex align-items-center">
            <section>
              <div
                class="rt-container"
                :style="{ right: 25 + 8 * GetNumber() + 'px' }"
                @click="
                  handleLikeTweet(propsNew.likes.includes(authUser.user_id))
                "
              >
                <div
                  :id="'heart-timeline' + propsNew.id"
                  class="heart"
                  :class="
                    propsNew.likes.includes(authUser.user_id) ? 'active' : ''
                  "
                ></div>
              </div>
            </section>
            <span class="heart__number">{{ propsNew.likes.length }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="news__content f-w3 text-left" v-html="formatMessage()"></div>
    <div class="preview position-relative" v-if="propsNew.imageUrls.length > 0">
      <!-- <lightbox :cells="4" :items="propsNew.imageUrls"></lightbox> -->
      <LayoutImage :propsData="propsNew.imageUrls" />
      <!-- <carousel
        ref="carousel"
        :per-page="1"
        :mouse-drag="false"
        :paginationColor="'#B9B9B9'"
        :paginationActiveColor="'#464D77'"
        :paginationSize="6"
        :paginationPadding="4"
      >
        <slide
          v-for="(image, index) in propsNew.imageUrls"
          :key="index"
          class="position-relative"
        >
          <div
            @click="
              showImage(
                propsNew.imageUrls[propsNew.imageUrls.length - index - 1]
              )
            "
          >
            <img
              class="w-100 preview__img"
              :src="propsNew.imageUrls[propsNew.imageUrls.length - index - 1]"
              style="cursor: pointer; pointer-events: none"
            />
          </div>
        </slide>
      </carousel>
      <div
        v-if="currentSlide !== 0"
        class="position-absolute icon-slider icon-slider-pre"
        @click="pre()"
        style="cursor: pointer"
      >
        <img src="@/assets/image/message/pre.svg" alt="" />
      </div>
      <div
        v-if="currentSlide !== propsNew.imageUrls.length - 1"
        class="position-absolute icon-slider icon-slider-next"
        @click="next()"
        style="cursor: pointer"
      >
        <img src="@/assets/image/message/next.svg" alt="" />
      </div> -->
    </div>
    <div
      class="name__time f-w3 d-flex mt-3 mb-3 position-relative align-items-center"
      style="float: right"
    >
      <span class="name__time--span">{{
        formatDateTime(propsNew.createdAt)
      }}</span>
      <div class="_more" @click="showOption = !showOption" v-if="propsAction">
        <img
          v-if="showOption"
          :src="require('@/assets/image/dot-icon-active.svg')"
          alt="menu"
        />
        <img v-else :src="require('@/assets/image/dot-icon.svg')" alt="menu" />
      </div>
      <div
        class="options d-flex flex-column"
        :class="showOption ? 'active' : ''"
      >
        <div class="options__bg" @click="showOption = !showOption"></div>
        <div
          v-if="parseInt(propsNew.userId.id) === authUser.user_id"
          class="options__content"
          :id="'option_' + tabindex"
        >
          <div
            class="_item d-flex align-items-center"
            @click="handleEditTweet()"
          >
            <img
              :src="require('@/assets/image/timeline/time-line-tweet-edit.svg')"
              alt="report user /"
            />
            <span class="f-w3 accept">編集</span>
          </div>
          <div
            class="_item d-flex align-items-center"
            @click="handleDeleteTweet()"
          >
            <img
              :src="require('@/assets/image/timeline/icon-delete.svg')"
              alt="block user /"
            />
            <span class="block f-w3">削除</span>
          </div>
        </div>
        <div v-else class="options__content" :id="'option_' + tabindex">
          <div
            class="_item d-flex align-items-center"
            @click="handleReportTweet()"
          >
            <img
              :src="require('@/assets/image/timeline/time-line-success.svg')"
              alt="report user /"
            />
            <span class="f-w3 accept">通報</span>
          </div>
          <div
            class="_item d-flex align-items-center"
            @click="handleHideTweet()"
            v-if="propsTab == 1"
          >
            <img
              :src="require('@/assets/image/timeline/time-line-delete.svg')"
              alt="block user /"
            />
            <span class="block f-w3">非表示</span>
          </div>
          <div
            class="_item d-flex align-items-center"
            @click="handleBlock()"
            v-else-if="propsTab == 2"
          >
            <img
              src="@/assets/image/icon_profile/icon-block.svg"
              alt="block user /"
            />
            <span class="block f-w3">ブロック</span>
          </div>
        </div>
      </div>
    </div>
    <hr v-if="propsIndex < propsLength - 1" style="margin-top: 50px" />
    <ModalImage ref="modalImageTimeline">
      <img
        :src="image_timeline"
        alt=""
        class="map-image"
        style="pointer-events: none"
      />
    </ModalImage>
  </div>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapGetters, mapActions } from "vuex";
import firebase from "@/firebase";
import { USER_URL } from "@/api/api";
import service from "@/utils/axios";
import ModalImage from "@/components/ModalImage.vue";
import LayoutImage from "@/views/Common/Timeline/LayoutImage.vue";
import { urlText } from "@/utils/ui";

const storage = firebase.storage;

export default {
  name: "TimeListNewsAll",
  components: {
    ModalImage,
    LayoutImage
  },
  props: {
    propsTab: {
      type: Number,
      default: 1
    },
    propsScore: {
      type: Object
    },
    tabindex: {
      type: Number
    },
    propsIndex: {
      type: Number
    },
    propsLength: {
      type: Number
    },
    propsNew: {
      type: Object
    },
    propsAction: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user"
    })
  },
  // async created() {
  //   this.message = this.propsNew.message,
  //   this.message = urlText(this.propsNew.message);
  // //   // await this.formatImage();
  // },
  data() {
    return {
      showOption: false,
      currentSlide: 0,
      imageUrl: [],
      message: null,
      image_timeline: null,
      unHideTweet: true
    };
  },
  methods: {
    ...mapActions("firebase", ["deleteTweet", "hideTweet", "likeTweet"]),

    // formatImage() {
    //   if (this.propsNew.imageUrls && this.propsNew.imageUrls.length == 0)
    //     return;
    //   for (let i = this.propsNew.imageUrls.length - 1; i >= 0; i--) {
    //     this.imageUrl.push(this.propsNew.imageUrls[i]);
    //   }
    // },
    formatMessage() {
      this.message = this.propsNew.message;
      return urlText(this.message);
    },
    showImage(image_url) {
      this.image_timeline = image_url;
      this.$refs["modalImageTimeline"].openModal();
    },
    GetNumber() {
      return Number(String(this.propsNew.likes.length).length) - 1;
    },
    formatDateTime(data) {
      if (data) {
        data = data.toDate();
        let dateNow = new Date();
        let time = "";
        if (
          data.getFullYear() === dateNow.getFullYear() &&
          data.getMonth() === dateNow.getMonth() &&
          data.getDate() === dateNow.getDate()
        ) {
          let hour =
            data.getHours() < 10 ? "0" + data.getHours() : data.getHours();
          let minute =
            data.getMinutes() < 10
              ? "0" + data.getMinutes()
              : data.getMinutes();
          time += hour + ":" + minute;
        } else {
          if (data.getFullYear() !== dateNow.getFullYear()) {
            let year = data.getFullYear();
            time += year + "/";
          }
          let day = data.getDate() < 10 ? "0" + data.getDate() : data.getDate();
          let month =
            data.getMonth() + 1 < 10
              ? "0" + (data.getMonth() + 1)
              : data.getMonth() + 1;
          time += month + "/" + day + " ";
        }

        return time;
      }
    },
    next() {
      const currentPage = this.$refs["carousel"].currentPage;
      if (currentPage < this.propsNew.imageUrls.length - 1) {
        this.currentSlide++;
        this.$refs["carousel"].goToPage(this.currentSlide);
      }
    },
    pre() {
      const currentPage = this.$refs["carousel"].currentPage;
      if (currentPage > 0) {
        this.currentSlide--;
        this.$refs["carousel"].goToPage(this.currentSlide);
      }
    },
    redirectProfile() {
      if (this.authUser.sex == 1) {
        if (this.authUser.user_id != parseInt(this.propsNew.userId.id)) {
          this.$router.push({
            name: "CastProfile",
            params: { id: parseInt(this.propsNew.userId.id) }
          });
        } else {
          this.$router.push({
            name: "ProfileViewMyProfile"
          });
        }
      } else {
        if (this.authUser.user_id != parseInt(this.propsNew.userId.id)) {
          this.$router.push({
            name: "MenProfile",
            params: { id: parseInt(this.propsNew.userId.id) }
          });
        } else {
          this.$router.push({
            name: "CastMyPageProfile"
          });
        }
      }
    },
    favorite(id) {
      if (id != null && this.propsNew.like == false) {
        this.propsNew.numberHeart++;
        this.propsNew.like = true;
        return;
      }
      this.propsNew.numberHeart--;
      this.propsNew.like = false;
    },
    handleEditTweet() {
      this.$emit("editTweet", this.propsNew);
      this.showOption = false;
    },
    async handleDeleteTweet() {
      let removeImage = this.propsNew.imageUrls.map(imageUrl => {
        let fileRef = storage.refFromURL(imageUrl.url);
        fileRef.delete();
      });
      await Promise.all(removeImage);
      await this.deleteTweet(this.propsNew.id);
      this.$toast("削除しました", "通知", "success");
      this.showOption = false;
    },
    handleReportTweet() {
      this.$root.$refs.loading.start();
      let data = {
        user_id: this.propsNew.userId.id,
        type: 2,
        message: this.propsNew.message,
        image_urls: this.propsNew.imageUrls
      };
      let msg = "通報しました。";
      service({
        url: USER_URL + "/report",
        method: "POST",
        data
      })
        .then(() => {
          this.$toast(msg, "通知", "success");
          this.showOption = false;
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
          this.showOption = false;
        });
    },
    handleBlock() {
      this.$root.$refs.loading.start();
      let data = { user_block_id: this.propsNew.userId.id };
      let msg = "ブロックしました。";
      service({
        url: USER_URL + "/block",
        method: "POST",
        data
      })
        .then(() => {
          this.$emit("handleBlock", this.propsNew.userId.id);
          this.$toast(msg, "通知", "success");
          this.showOption = false;
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
          this.showOption = false;
        });
    },
    handleHideTweet() {
      this.hideTweet({
        userId: this.authUser.user_id,
        tweetId: this.propsNew.id
      });
      this.$toast("非表示にしました", "通知", "success");
      this.unHideTweet = false;
      this.showOption = false;
      this.$emit("hideTweet");
    },
    async handleLikeTweet(is_like) {
      let id = "heart-timeline" + this.propsNew.id;
      document.getElementById(id).classList.add("active");
      let params = {
        is_like: is_like,
        id: this.propsNew.id
      };
      await this.$emit("like", params);
      // let action = "like";
      // if (is_like) {
      //   action = "unlike";
      // }
      // this.likeTweet({
      //   userId: this.authUser.user_id,
      //   tweetId: this.propsNew.id,
      //   action: action
      // });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/timeLineNew.scss";
@import "@/assets/scss/_fontFamily.scss";
.map-image {
  width: 100%;
  margin-top: 40px;
  height: 100%;
  max-height: 500px;
}
._more {
  position: relative;
  z-index: 1;
}
#new {
  /deep/ .app-modal.default .app-modal__dialog {
    width: 100%;
    max-width: 1080px;
  }
}
#new {
  /deep/ .slick-dots li {
    width: 11px;
    height: 28px;
    margin: 0;
  }
  /deep/ .slick-prev {
    display: none !important;
  }
  /deep/ .slick-next {
    display: none !important;
  }
}
#new {
  /deep/ .VueCarousel-dot-container {
    margin-top: 0 !important;
    button {
      margin-top: 0 !important;
    }
  }
}
._more {
  cursor: pointer;
  img {
    height: 4px;
    width: 18px;
  }
}
.new {
  border-radius: 10px;
  background-color: #ecf5ff;
  border: 1px solid #0178fd;
}
.news__content {
  word-break: break-word;
  white-space: pre-line;
  margin-bottom: 20px;
}
.accept {
  color: #000000;
  font-size: 16px;
}
.block {
  color: #f14e4e;
  font-size: 16px;
}
.options {
  ._item {
    height: 50px;
    padding: 0 19px;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
      margin-right: 11px;
    }

    &:nth-child(1) {
      border-bottom: 1px solid #d9d9d9;
    }
  }
}
.preview {
  padding-left: 58px;
  margin-top: 10px;
  &__img {
    border-radius: 5px;
  }
  .icon-slider {
    display: none;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 33px;
      height: 33px;
    }
  }
  .icon-slider-next {
    right: 0;
  }
  .icon-slider-pre {
    left: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .options {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    transition: ease 0.5s;
    display: flex;
    align-items: flex-end;
    z-index: 100;
    &.active {
      visibility: visible;

      .options__content {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &__content {
      background-color: #fff;
      border-radius: 20px 20px 0 0;
      opacity: 0;
      transition: ease 0.5s;
      transform: translateY(50px);
      width: 100%;
      position: fixed;
      bottom: 0;
      width: 100%;
    }

    &__bg {
      width: 100%;
      height: calc(100vh - 100px);
    }
  }
}
@media only screen and (min-width: 1200px) {
  /deep/ .lb-grid {
    height: 283px;
  }
  .preview {
    padding-left: 70px;
  }
  // ._dot {
  //   @include borderRadius(6px, 6px, 50%);
  // }
  .map-image {
    max-height: 500px;
    max-width: 1080px;
  }
  #new {
    /deep/ .slick-prev {
      display: block !important;
      &::before {
        font-size: 40px;
      }
    }
    /deep/ .slick-next {
      display: block !important;
      &::before {
        font-size: 40px;
      }
    }
  }

  .options {
    position: absolute;
    width: 218px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    transition: ease 0.2s;
    transform-origin: top right;
    transform: scale(0.6);
    visibility: hidden;
    opacity: 0;
    top: 30px;
    right: 0;
    box-shadow: 0 0 15px #00000029;
    z-index: 2;
    ._item {
      padding: 0 19px;
    }

    &.active {
      transform: scale(1);
      visibility: visible;
      opacity: 1;
    }
  }
}
.rt-container {
  position: absolute;
}
.heart {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: url("~@/assets/image/timeline/heart.png") no-repeat;
  background-position: 0 0;
  cursor: pointer;
  animation: fave-heart 1s steps(28);
  background-size: 2030px;
  &.active {
    background-position: -1960px 0;
    transition: background 1s steps(28);
  }
}

@media only screen and (min-width: 768px) {
  .preview {
    .icon-slider {
      display: block;
    }
  }
}
</style>
